import React from "react";
import "../../assets/scss/alexis/_guidelines.scss";
import PageTitle from "../page-title";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AccessibilityComp = () => {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <PageTitle
                            classOption="page-title-content  text-center"
                            subTitle="שווין הוא אבן הדרך להצלחה"
                            title="הצהרת נגישות"
                        />
                    </div>
                </div>
            </div>

            <div className="guide-intro">
                <p className="guide-text">
                    <p>
                        <a href="https://adamo.co.il/" data-fr-linked="true">
                            https://adamo.co.il/
                        </a>
                        נעשו מאמצים להנגיש את תכני האתר לכלל האוכולוסיה.
                    </p>
                    <b>מהו אתר נגיש?</b>
                    <br />
                    אתר שמוגדר “אתר נגיש” אמור לספק חווית גלישה נעימה ונוחה
                    לכולם. מדובר על אתר ידידותי ,זמין 24 שעות ביממה לשימוש עבור
                    אנשים עם מוגבלויות, לקויות ואנשים המתקשים בהפעלת המחשב
                    והאינטרנט.
                    <br />
                    אתר נגיש מאפשר לכלל הגולשים לגלוש בנוחות ובמהירות ולהינות
                    מהתכנים והשירותים המוצעים באתר בצורה הטובה ביותר ללא הבדלים
                    בגישה.
                    <br />
                    האתר נבנה והונגש למענכם בהתאם להוראות הנגישות המופיעות ב –
                    W3C’s Web Content Accessibility Guidelines 2.0, level AA
                    <br />
                    נתקלתם בבעיה? ספרו לנו!
                    <br />
                     אנו עושים את מירב המאמצים להציג אתר נגיש שנדרש על פי חוק,
                    אנו משתדלים לשמור ולתחזק את האתר ברמה הטובה ביותר. במידה
                    ומצאתם ו\או נתקלתם בקשיים בגלישה באתר, אל תהסס\י לדווח לנו
                    בטופס צרו הקשר באתר
                    <br />
                    אנו נטפל בבעיה ונחזור אליך בהקדם עם פרטים על טיפולה.
                    <br />
                    תאריך עידכון ההצהרה
                    <br />
                    <i>הצהרת הנגישות עודכנה לאחרונה בתאריך: 1/08/2023</i>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                        <div
                            className=" text-center"
                            data-aos="fade-up"
                            data-aos-delay="500"
                        >
                            <div className="form-group mb-0">
                                <button className="btn-guide" href="/contact">
                                    שליחת טופס
                                </button>
                            </div>
                        </div>
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default AccessibilityComp;
