import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";
import Guidelines from "../components/guidelines";

const GuidePage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="AdamO || GuideLines" />
                <div className="wrapper about-page-wrapper">
                    <Header classOption="hb-border" />
                    <div className="main-content">
                        <Guidelines />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default GuidePage;
