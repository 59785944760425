import React from "react";
import "../../assets/scss/alexis/_guidelines.scss";
import PageTitle from "../page-title";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Guidelines = () => {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <PageTitle
                            classOption="page-title-content  text-center"
                            subTitle="תקנון אתר"
                            title="מדיניות פרטיות/תנאי שימוש"
                        />
                    </div>
                </div>
            </div>

            <div className="guide-intro">
                <p className="guide-text">
                    <b>מבוא –</b>
                    <p>
                        ברוכים הבאים לאתר AdamO (להלן: &quot;האתר&quot;) המופעל
                        על ידי אדם אוברלנדר, ח.פ 313581316 , מכתובת ביאליק, קרית
                        אונו (להלן: &quot;הנהלת האתר&quot; ו/או
                        &quot;החברה&quot;).
                    </p>
                    <p>
                        כל שימוש באתר מהווה הסכמה לתקנון זה, על התנאים וההגבלות
                        הכלולים בו.
                    </p>
                    <p>
                        התקנון עלול להשתנות מעת לעת, ועל המשתמש להתעדכן בתקנון
                        בכל כניסה לאתר. גלישה באתר ו/או שימוש בו מכל סוג, כמוהם
                        כהסכמה לתנאי התקנון והתחייבות לפעול על פיהם. מובהר כי
                        התקנון מהווה הסכם משפטית מחייבת לכל דבר ועניין ומחייב את
                        המשתמש על כל שימושיו באתר.
                    </p>
                    <ul>
                        <li>
                            האמור בתקנון זה בלשון זכר הוא לשם הנוחות בלבד
                            והתקנון מתייחס לבני שני המינים באופן שווה.
                        </li>
                        <li>
                            השירותים באמצעות האתר כפופים לתנאים המפורטים בתקנון
                            זה.
                        </li>
                        <li>
                            עצם הזמנת שירותים באתר תהווה הצהרה מצד הלקוח כי קרא
                            את הוראות תקנון זה, הבין אותן והסכים להן. התקנון
                            מהווה חוזה מחייב בין המשתמש לבין הנהלת האתר.
                        </li>
                    </ul>
                    <p>השירותים &ndash;</p>
                    <ul>
                        <li>האתר מאפשר שירותי &nbsp; צילום מקצועי והדרכות .</li>
                        <li>
                            ניתן להזמין שירותים באמצעות יצירת קשר עם הנהלת האתר
                            בהתאם לזמינותו ומיקומו הגיאוגרפי.
                        </li>
                        <li>
                            ניתן לשלם עבור שירותי האתר באמצעות לפי ההסכם שסוכם
                            בין מזמין השירות לנותן השירות בכפוף לאישור הנהלת
                            האתר.
                        </li>
                        <li>
                            מחיר השירותים הינם ללא מע&quot;מ בכפוף לחוק המיסים
                            עבור &quot;חברה בע&quot;מ&quot;.
                        </li>
                        <li>
                            הנהלת האתר שומרת לעצמה את הזכות להפסיק את שירותיה
                            בכל עת.
                        </li>
                        <li>
                            הזכאות לשימוש באתר הינה לכל אזרח אשר מלאו לו 18
                            שנים. הזמנה תתאפשר למי שברשותו חשבון בנק פעיל ו/או
                            בעל כרטיס אשראי ישראלי תקף והינו בעל תיבת דואר
                            אלקטרוני אשר כתובתה תינתן בעת הרכישה באתר. קטין
                            המבצע פעולות ייחשב כקטין אשר קיבל את רשות
                            הוריו/אפוטרופוס.
                        </li>
                    </ul>
                    <p>זמינות ומיקום אזורי &ndash;</p>
                    <ul>
                        <li>
                            זמינות השירותים כפופה למועד אשר נקבע עם הנהלת האתר.
                        </li>
                        <li>
                            בכל שאלה או בירור לגבי השירותים ניתן לחייג אל גיא
                            אגא בטלפון 052-6389420 .
                        </li>
                    </ul>
                    <p>אחריות ושירות &ndash;</p>
                    <ul>
                        <li>
                            השירותים ינתנו בהתאם לחבילות השירותים אשר יוצעו
                            ע&quot;י הנהלת האתר עם תחילת השירות.
                        </li>
                        <li>
                            אורך השירות הינו ע&quot;פ מועד הזמן אשר נקבע מראש.
                        </li>
                        <li>
                            הנהלת האתר אינה אחראית על נזקים פיזיים או כספיים או
                            כל נזק אחר הנגרמו במהלך השירות או בעקבות השימוש
                            במוצרים.
                        </li>
                        <li>
                            הנהלת האתר מסירה מעליה כל אחריות מהתכנים הנכתבים
                            באתר או מהשימוש בהם והשלכותיהם.
                        </li>
                    </ul>
                    <p>אבטחת מידע ופרטיות &ndash;</p>
                    <ul>
                        <li>
                            הנהלת האתר, כמו גם חברות אחיות וגורמי משנה נוספים
                            הפועלים מטעם הנהלת החברה לאותם הסטנדרטים, רשאיות
                            להשתמש במידע המופיע בטופס על מנת להביא לך את המידע
                            והשירותים המבוקשים. מידע אישי זה לא ייחשף ולא ייעשה
                            בו שימוש נוסף למטרות שיווקיות ללא רשות ולא יועבר לצד
                            שלישי ללא אישור מפורש מהלקוח.
                        </li>
                        <li>
                            תוכן השירות וחשיפת מידע אישי במהלך השירות, לעולם לא
                            ייחשפו לאדם אחר או כל גורם ללא אישור בכתב מהלקוח.
                            הנהלת האתר מחויבת לפרטיות שלך ופרטיות זו הינה בראש
                            סדר העדיפויות שלנו.
                        </li>
                        <li>
                            החברה מתחייבת שלא לעשות שימוש במידע המסופק לה
                            ע&quot;י הקונים אלא על מנת לאפשר את הרכישה באתר
                            מכירות ובהתאם לכל דין.
                        </li>
                        <li>
                            באמצעות לחיצה על כפתור &quot;שלח&quot; אני מאשר
                            בחתימתי את הטופס ואת תנאיו.
                        </li>
                        <li>
                            האתר מאובטח באמצעות שימוש באמצעי אבטחה מתקדמים אשר
                            מטרתם להבטיח שימוש תקין וגלישה בטוחה באתר וכן על מנת
                            לשמור על פרטיות המשתמשים. כל המשתמש באתר ובשירותיו
                            מתחייב כי לא יעשה כל פעילות שיש בה כדי לשבש את
                            פעילות האתר, גניבת מידע על משתמשים ופריצה של מנגנוני
                            האבטחה של האתר.
                        </li>
                        <li>
                            במקרה של שימוש לרעה, הנהלת האתר תפעל נגד כל פעילות
                            בכל דרך חוקית אשר תעמוד לרשותה לרבות חסימת המשתמש
                            מגישה לאתר ונקיטת הליכים משפטיים נגד המשתמש באם יפעל
                            כאמור.
                        </li>
                    </ul>
                    <p>קניין רוחני &ndash;</p>
                    <ul>
                        <li>
                            כל זכויות הקניין הרוחני באתר זה הכוללים זכויות
                            יוצרים, זכויות הפצה, סודות מסחריים, סימני המסחר וכל
                            הקניין הרוחני מכל סוג שהוא, הן בנוגע לעיצוב ועמוד
                            האתר, הן באשר לתכנים המופיעים בו הינן רכושה הבלעדי
                            של הנהלת האתר.
                        </li>
                        <li>
                            אין להעתיק, לשכפל, להפיץ, לפרסם או להשתמש בכל דרך
                            אחרת במידע כלשהו מן האתר ו/או מהאתר, אלא אם ניתנה
                            הסכמה לכך מראש ובכתב מטעם הנהלת האתר.
                        </li>
                    </ul>
                    <p>דין וסמכות שיפוט &ndash;</p>
                    <ul>
                        <li>
                            פרשנותו ואכיפתו של תקנון זה ו/או כל פעולה או סכסוך
                            הנובע ממנו יעשו בהתאם לדין הישראלי בלבד ולבית המשפט
                            המוסמך בפתח תקווה תהא מסורה סמכות השיפוט הבלעדית.
                        </li>
                    </ul>
                    <p>יצירת קשר &ndash;</p>
                    <ul>
                        <li>
                            לכל שאלה ופנייה ניתן ליצור קשר עם הנהלת האתר בטלפון
                            052-6389420או במייל&nbsp;
                            <a href="mailto:adam@beeu.co.il">adam@beeu.co.il</a>
                            בשעות הפעילות של האתר
                        </li>
                    </ul>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                        <div
                            className=" text-center"
                            data-aos="fade-up"
                            data-aos-delay="500"
                        >
                            <div className="form-group mb-0">
                                <button className="btn-guide" href="/contact">
                                    {" "}
                                    שליחת טופס
                                </button>
                            </div>
                        </div>
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default Guidelines;
