const Map = () => {
    return (
        <iframe
            title="this is title"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13526.584660433098!2d34.866940668662856!3d32.051767583600515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4a79a53c296d%3A0x9c1594841c109c08!2z16fXqNeZ16og15DXldeg15U!5e0!3m2!1siw!2sil!4v1688853961316!5m2!1siw!2sil"
        ></iframe>
    );
};

export default Map;
